@import "./Variables.scss";

.fs-24 {
  font-size: $font-size-24px;
  line-height: $line-height-36px;
  color: $dark75;
  font-weight: $font-weight-bold;
}
.fs-14 {
  font-size: $font-size-14px;
  line-height: $line-height-16px;
  color: $gray974;
  font-weight: $font-weight-regular;
}

.fs-16 {
  font-size: $font-size-16px;
  line-height: $line-height-24px;
  color: $dark75;
  font-weight: $font-weight-bold;
}
.fs-16-g {
  font-size: $font-size-16px;
  line-height: $line-height-18px;
  color: $gray974;
  font-weight: $font-weight-regular;
}

.fs-14-b {
  font-size: $font-size-14px;
  line-height: $line-height-22px;
  color: $dark75;
  font-weight: $font-weight-bold;
}

.fw-bold {
  font-weight: $font-weight-bold;
}
