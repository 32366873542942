@import "../../CommonStyle/Variables.scss";
.card-container {
  .img-section {
    height: 160px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .card-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .card-data-section {
    padding: 20px 15px;
    background-color: $light;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .date-tag {
      font-size: 24px;
      line-height: $line-height-36px;
      letter-spacing: 0.1px;
      color: $dark75;
      font-weight: $font-weight-bold;
    }

    .card-title-tag {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      color: $gray44f;
      font-weight: $font-weight-bold;
    }

    .card-desc-tag {
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      color: $gray29d;
      font-weight: $font-weight-regular;
    }

    .ant-avatar-group {
      gap: 20px;
      

    }
  }
}
