/*======================================
        Color's Variables
=======================================*/

$light: #fff;
$lightfc: #f8fafc;
$lightf6: #f6f6f6;
$lightf7: #f2f4f7;
$lightd9: #d9d9d9;
$lightfb: #fafafb;
$lightea: #e2e2ea;
$lighte7: #e7e7e7;
$dark: #000;
$dark15: #151515;
$dark75: #171725;
$dark4f: #44444f;
$darko88: rgba(0, 0, 0, 0.88);
$darko20: rgba(32, 32, 32, 0.2);
$darko2: rgba(0, 0, 0, 0.2);
$dark1E: #0f111e;
$dark3F: #33363f;
$darkf15: #f1f1f5;

$grayB3: #98a2b3;
$gray974: #696974;
$grayD9: #d9d9d9;
$gray6A: #6a6a6a;
$gray56: #565656;
$grayAE: #aeaeae;
$gray65: #565a65;
$gray69: #696969;
$gray75: #757575;
$gray74: #696974;
$gray29d: #92929d;
$gray44f: #44444f;
$gray725: #171725;
$gray94o55: rgba(94, 94, 94, 0.55);

$grayo20: rgba(86, 86, 86, 0.2);

$purplFD: #8043fd;
$purpleFF: #9747ff;
$purple0FF: #8f00ff;
$purple60: #261c60;
$blueFA: #2e90fa;
$blurF7: #615cf7;
$blueFF: #1677ff;

$blue1FF: #0061ff;
$blue2ff: #0062ff;
$blue0FF: #1890ff;
$blue6ff: #0062ff;
$blue5ff: #1e75ff;
$green1A: #52c41a;
$green6A: #12b76a;
$green5B: #25bd5b;
$green6AO30: #12b76a;
$green4F: #32854f;
$green49: #0eb549;
$green98: #3dd598;

$orange800: #ffa800;
$orange100: #ff9100;
$orange74a: #ff974a;

$red4f: #ff4d4f;
$red5fa: #fc5a5a;
/*======================================
        Font Size Variables
=======================================*/

$font-size-58px: 58px;
$font-size-54px: 54px;
$font-size-52px: 52px;
$font-size-48px: 48px;
$font-size-46px: 46px;
$font-size-44px: 44px;
$font-size-40px: 40px;
$font-size-39px: 39px;
$font-size-38px: 38px;
$font-size-36px: 36px;
$font-size-32px: 32px;
$font-size-31px: 31px;
$font-size-30px: 30px;
$font-size-28px: 28px;
$font-size-25px: 25px;
$font-size-24px: 24px;
$font-size-23px: 23px;
$font-size-22px: 22px;
$font-size-21px: 21px;
$font-size-19px: 19px;
$font-size-17px: 17px;
$font-size-18px: 18px;
$font-size-16px: 16px;
$font-size-14px: 14px;
$font-size-12px: 12px;
$font-size-10px: 10px;
$font-size-9px: 9px;

/*======================================
        Line Height Variables
=======================================*/
$line-height-91px: 91px;
$line-height-79px: 79px;
$line-height-69px: 69px;
$line-height-65px: 65px;
$line-height-61px: 61px;
$line-height-60px: 60px;
$line-height-55px: 55px;
$line-height-52px: 52px;
$line-height-51px: 51px;
$line-height-44px: 44px;
$line-height-42px: 42px;
$line-height-40px: 40px;
$line-height-38px: 38px;
$line-height-39px: 39px;
$line-height-36px: 36px;
$line-height-33px: 33px;
$line-height-32px: 32px;
$line-height-31px: 31px;
$line-height-30px: 30px;
$line-height-29px: 29px;
$line-height-28px: 28px;
$line-height-26px: 26px;
$line-height-27px: 27px;
$line-height-25px: 25px;
$line-height-24px: 24px;
$line-height-23px: 23px;
$line-height-22px: 22px;
$line-height-20px: 20px;
$line-height-19px: 19px;
$line-height-18px: 18px;
$line-height-17px: 17px;
$line-height-16px: 16px;
$line-height-12px: 12px;

/*======================================
        Font Weight Variables
=======================================*/
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-heavy: 900;
