@import "../../CommonStyle/Variables.scss";
.logo-pic-card-wrapper {
  background-color: $light;
  padding: 20px 40px 25px 20px;
  border-radius: 15px;
  gap: 20px;

  .pic-desc-area {
    padding-top: 20px;
  }

  .status-title {
    font-size: $font-size-16px;
    line-height: $line-height-26px;
    color: $gray44f;
    font-weight: $font-weight-bold;
  }

  .pic-headers {
    font-size: $font-size-16px;
    line-height: $line-height-26px;
    color: $blue2ff;
    font-weight: $font-weight-bold;
  }
  .img-otr {
    height: 146px;

    .img-inr {
      width: 100%;
      height: 100%;
      object-fit: inherit;
    }
  }
}
