@import "../../CommonStyle/Variables.scss";

.calender-main-wrapper {
  background-color: $light;
  padding: 50px 40px;
  border-radius: 15px;

  .active-month-btn {
    .active-month-tag {
      font-size: $font-size-18px;
      line-height: $line-height-27px;
      color: $dark75;
      font-weight: $font-weight-bold;

      .active-year {
        font-weight: $font-weight-semibold;
      }
    }
  }

  .custom-calender-line {
    position: relative;
    .custom-line {
      //   position: absolute;
      top: 50%;
      //   transform: translateY(50%, -50%);
      border-bottom: 1px solid $lightea;
    }
  }

  .custom-calender-card {
    border: none !important;
    width: 100%;

    .react-calendar__tile {
      border-radius: 15px;
      color: $gray725;
      font-size: 16px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0px;
      padding-right: 0px;

      //   line-height: $line-height-52px;
      margin: 5px;
      position: relative;

      .event-marker {
        position: absolute;
        bottom: 2px;
        left: 50%;
        color: #0062ff;
        font-size: 15px;
        font-weight: $font-weight-bold;
        transform: translateX(-50%);
      }
    }

    .react-calendar__tile:hover {
      background-color: none !important;
      color: #0062ff;
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: none !important;
    }

    .react-calendar__tile--now {
      background: transparent;
    }

    .react-calendar__tile--active {
      background: #0062ff;
      color: white !important;
      position: relative;

      .event-marker {
        position: absolute;
        bottom: 2px;
        left: 50%;
        color: $light;
        font-size: 15px;
        font-weight: $font-weight-bold;
        transform: translateX(-50%);
      }
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font: inherit;
      font-size: $font-size-12px;
      color: $gray29d;
      font-weight: bold;
      text-decoration: none !important;
    }

    abbr[title] {
      -webkit-text-decoration: underline !important;
      text-decoration: none !important;
      cursor: help;
      text-decoration-skip-ink: none;
    }

    .react-calendar__month-view__days {
      display: flex;
      justify-content: space-between;
    }
  }
}
