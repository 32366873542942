@import "../../CommonStyle/Variables.scss";
.pending-wrapper {
  .card-header {
    padding: 0px 25px;
    .add-icon:hover svg path {
      fill: $green98;
    }
    .pending-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
      color: $gray74;
    }
  }
  .pending-card-otr {
    background-color: $light;
    padding: 20px 25px;
    border-radius: 15px;
    row-gap: 20px;
    .heading-section {
      .task-title {
        font-size: $font-size-14px;
        line-height: $line-height-22px;
        color: $dark75;
        font-weight: $font-weight-medium;
      }

      .task-desc {
        font-size: $font-size-14px;
        line-height: 16px;
        font-weight: $font-weight-regular;
        color: $gray74;
      }
    }

    .progress-section {
      .custom-badge {
        // background-color: #ff984a3b !important;
        background-color: $darkf15 !important;

        .badge-title {
          font-size: $font-size-14px;
          line-height: $line-height-16px;
          color: $gray29d;
          font-weight: $font-weight-regular;
        }
      }

      .custom-width {
        width: 20vw;
      }

      @media screen and (max-width: 768px) {
        .custom-width {
          width: 100%;
        }
      }
    }

    .ant-avatar-group {
      gap: 20px;
    }

    .add-icon:hover svg path {
      fill: $green98;
    }
  }
}
