@import "../../CommonStyle/Variables.scss";
.preview-card-wrapper {
  background-color: $light;
  border-radius: 15px;

  padding: 30px;

  .custom-badge {

    background-color: #ff984a3b !important;

    .badge-title {
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      color: $orange74a;
      font-weight: $font-weight-regular;
    }
  }

  .ant-avatar-group {
    gap: 20px;
  }

  .toggle-icon:hover .tg-icon {
    fill: $green98 !important;
  }
}

.project-img {
  height: 170.45px;
  border-radius: 15px;

  .project-img-inr {
    height: 100%;
    width: 100%;
    object-fit: fill;
    border-radius: 15px;
  }
}
