@import "../../CommonStyle/Variables.scss";
.Sales-Card-wrapper {
  background-color: $light;
  border-radius: 15px;
  // max-width: 300px;
  padding: 20px 25px;

  .card-title {
    font-size: $font-size-16px;
    line-height: $line-height-24px;
    letter-spacing: 0.1px;
    padding-bottom: 17px;
    font-weight: $font-weight-medium;
  }

  .sales-ratio {
    gap: 12px;
    padding-bottom: 10px;
    .price-title {
      font-size: $font-size-28px;
      line-height: $line-height-42px;
      color: $dark75;
      font-weight: $font-weight-bold;
    }

    .percentge-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      color: $green98;
    }
  }

  .description {
    font-size: $font-size-14px;
    line-height: $line-height-16px;
    color: $gray74;
  }
}
