@import "../../CommonStyle/Variables.scss";
.studio-card-wrapper {
  background-color: $light;
  padding: 20px;
  border-radius: 15px;

  .card-header {
    .add-icon:hover svg path {
      fill: $green98;
    }
    .card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
    }
  }

  .ant-avatar-group {
    gap: 20px;
  }
}
