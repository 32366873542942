@import "../../CommonStyle/Variables.scss";
.stories-card-wrapper {
  background-color: $light;
  border-radius: 15px;
  padding-top: 20px;
  .card-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $darkf15;

    .add-icon:hover svg path {
      fill: $green98;
    }
    .card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
      padding-left: 25px;
    }

    .add-icon {
      padding-right: 25px;
    }
  }

  .card-data-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $darkf15;

    .add-stories-header {
      padding: 0px 25px;

      .title-class {
        font-size: $font-size-14px;
        line-height: $line-height-22px;
        color: $dark75;
        font-weight: $font-weight-bold;
      }

      .title-desc {
        font-size: $font-size-14px;
        line-height: $line-height-22px;
        color: $dark75;
        font-weight: $font-weight-regular;
      }
    }

    .card-data-list {
      padding-left: 25px;
      padding-right: 25px;
      .profile-section {
        gap: 15px;
        .profile-titles {
          .name-title {
            font-size: $font-size-14px;
            line-height: $line-height-22px;
            color: $dark75;
            font-weight: $font-weight-bold;
          }

          .idtitle {
            font-size: $font-size-14px;
            line-height: $line-height-16px;
            color: $gray29d;
            font-weight: $font-weight-regular;
          }
        }

        .profile-titles:hover {
          .name-title {
            color: $blue6ff !important;
          }
          .idtitle {
            color: $red4f;
          }
        }
      }
    }
  }

  .stories-card-footer {
    padding: 20px 0px;
    color: $blue6ff;
    font-size: $font-size-12px;
    line-height: $line-height-18px;
    font-weight: $font-weight-bold;
    background-color: #0073ff09;
  }

  .message-icon:hover .msj-path {
    fill: $blue1FF !important;
  }

  .delete-icon:hover .d-icon {
    fill: red !important;
  }
}
