@import "../../CommonStyle/Variables.scss";
.complete-Profile-Card-wraper {
  background-color: $light;
  border-radius: 15px;
  padding: 25px;

  .head-title {
    font-size: $font-size-14px;
    line-height: $line-height-22px;
    color: $dark75;
    font-weight: $font-weight-bold;
  }
}
