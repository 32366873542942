@import "../../CommonStyle/Variables.scss";
.earning-card-wrapper {
  background-color: $light;
  border-radius: 15px;
  padding: 25px;

  .card-header {
    .card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
    }

    .ant-segmented {
      background-color: transparent;
      border: 1px solid $darkf15;
    }

    .ant-segmented-item-selected {
      background-color: $blue1FF;
      color: $light;
    }
  }

  .recharts-cartesian-axis-line {
    display: none;
  }
  .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .recharts-tooltip-wrapper {
    border: none !important;
    outline: none !important;
    background-image: url("../../assets/images/Shape.png") !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100%;
    padding: 10px;
    min-width: 115px !important;
    .recharts-default-tooltip {
      border: none !important;
      // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      // border-radius: 25px;
      background: none !important;

      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;

      .recharts-tooltip-label {
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        color: $gray974 !important;
        font-weight: $font-weight-regular;
      }

      .recharts-tooltip-item-list {
        .recharts-tooltip-item-name {
          display: none !important;
        }
        .recharts-tooltip-item-separator {
          display: none;
        }

        .recharts-tooltip-item-value {
          color: $dark;
          font-size: $font-size-16px;
          line-height: $line-height-24px;
          font-weight: $font-weight-bold;
        }
        .recharts-tooltip-item-value::before {
          content: "$";
        }
      }
    }
  }

  .recharts-line {
    background-image: url("../../assets/images/line.png") !important;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;
    width: 100%;
  }
}
