.Show-components-card-wrapper {
  background-color: #001529;
  border-radius: 15px;
  padding: 20px;

  .component-section {
  }

  .button-section {
  }
}

.ant-pagination-item-active {
  background-color: #0d6efd !important;

  color: #ffffff;
}

.ant-pagination-total-text {
  display: none !important;
}

.ant-pagination-item {
  color: #ffffff !important;
}

.ant-pagination-item-link {
  color: #ffffff !important;
}

.ant-select-selector {
  background-color: #0d6efd !important;
  border: none !important;
  outline: none;
}

.ant-select-selection-item {
  color: #ffffff !important;
}

.ant-select-selection-item::after {
  display: none !important;
}

@media only screen and (max-width: 576px) {
}

.ant-select-arrow {
  display: none !important;
}

.ant-ribbon {
  position: unset !important;
}

.ant-ribbon-corner {
  display: none !important;
}

.ant-ribbon-wrapper {
  width: fit-content !important;
}

.ant-select-selection-item {
  display: contents;
}
