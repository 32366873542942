@import "../../CommonStyle/Variables.scss";
.email-filter-wrapper {
  background-color: $light;
  border-radius: 15px;
  padding: 63px 20px 20px 20px;

  .filter-form-wrapper {
    .custom-form-label {
      font-size: $font-size-12px;
      line-height: $line-height-12px;
      font-weight: $font-weight-regular;
      color: $gray29d;
    }

    .custom-form-select {
      outline: none !important;
      border: none;
      border-bottom: 1px solid $lightea !important;
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      color: $gray44f;
      font-weight: $font-weight-semibold;
    }

    .custom-form-input {
      outline: none !important;
      border: none;
      border-bottom: 1px solid $lightea !important;
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      color: $gray44f;
      font-weight: $font-weight-semibold;
      border-radius: 0px !important;
    }

    .custom-check-label {
      font-size: $font-size-12px;
      line-height: $line-height-12px;
      font-weight: $font-weight-semibold;
      color: $gray44f;
    }

    .custom-outline-btn {
      border: 1px solid $lightea !important;
      font-size: $font-size-12px;
      line-height: $line-height-16px;
      font-weight: $font-weight-semibold;
      padding: 10px 30px;
      border-radius: 15px;
      color: $gray974;
    }

    .custom-secondary-btn {
      font-size: $font-size-12px;
      line-height: $line-height-16px;
      font-weight: $font-weight-semibold;
      color: $light;
      padding: 10px 30px;
      border-radius: 15px;
    }
  }
}
