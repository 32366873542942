@import "../../CommonStyle/Variables.scss";
.design-ui-wrapper {
  .design-ui-model {
    .model-header {
      .custom-mark-badge {
        background-color: transparent !important;
        border: 1px solid $lighte7 !important;
        .badge-title {
        }
      }
    }
  }

  .modal-content {
  }
}
.custom-mark {
  background-color: transparent;
  border: 1px solid $lighte7 !important;
  .badge-title {
    font-size: $font-size-12px;
    line-height: $line-height-16px;
    color: $gray29d;
    font-weight: $font-weight-regular;
  }
}

.custom-modal-body {
  padding: 32px 40px;

  .model-header-data-row {
    border-bottom: 1px solid $lightea;
  }

  .modal-header-data {
    .project-title {
      font-size: $font-size-24px;
      line-height: $line-height-24px;
      color: $gray44f;
      font-weight: $font-weight-bold;
    }

    .added-by-desc {
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      color: $gray974;
      font-weight: $font-weight-regular;
      .added-by {
        color: $blue2ff;
      }
    }

    .modal-date-tag {
      font-size: $font-size-16px;
      line-height: $line-height-18px;
      color: $gray44f;
      font-weight: $font-weight-semibold;
    }
  }

  .section-title-tag {
    font-size: $font-size-14px;
    line-height: $line-height-22px;
    color: $gray725;
    font-weight: $font-weight-bold;
  }

  .show-full {
    font-size: $font-size-14px;
    line-height: $line-height-22px;
    color: $blue2ff;
    text-decoration: underline;
    font-weight: $font-weight-regular;
  }

  .img-path {
    font-size: $font-size-12px;
    line-height: $line-height-16px;
    color: $gray29d;
  }

  .custom-check {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    position: relative;
  }

  .custom-check:checked::before {
    content: "\2713"; /* Unicode character for checkmark */
    font-size: 16px; /* Adjust the font size of the checkmark */
    color: $light; /* Set the color of the checkmark */
    position: absolute;
    background-color: $green98;
    border-radius: 6px;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translate(-50%, -50%);
  }

  .task-title {
    font-size: $font-size-16px;
    line-height: $line-height-26px;
    color: $gray44f;
    font-weight: $font-weight-semibold;
  }

  .task-date {
    font-size: $font-size-14px;
    line-height: $line-height-16px;
    color: $gray29d;
    font-weight: $font-weight-regular;
  }

  .profile-name {
    font-size: $font-size-14px;
    line-height: $line-height-16px;
    color: $gray29d;
    font-weight: $font-weight-regular;
  }

  .comment-card {
    background-color: $lightfb;
    padding: 14px 20px;
    border-radius: 15px;
    position: relative;
  }

  .comment-name {
    font-size: $font-size-14px;
    line-height: $line-height-22px;
    font-weight: $font-weight-bold;
    color: $gray725;
  }

  .comment-designation {
    font-weight: $font-weight-semibold;
  }

  .comment-date {
    font-size: $font-size-12px;
    line-height: $line-height-16px;
    color: $gray29d;
    font-weight: $font-weight-regular;
  }

  .comment-desc-wrapper {
  }

  .comment-icon {
    position: relative;

    .comment-icon-img {
      border: 4px solid $light;
      position: absolute;
      top: 8px;
      left: -12px;
      z-index: 999;
    }
  }

  .new-comment-wrapper {
    background-color: $lightfb;
    padding: 20px 20px;
    border-radius: 15px;

    .new-comment-input-wrapper {
      border: 1px solid #d5d5dc;
      padding: 8px 14px;
      border-radius: 5px;
      background-color: $light;
    }

    .new-comment-input {
      font-size: $font-size-14px;
      line-height: $line-height-26px;
      font-weight: $font-weight-semibold;
      border: none;
      color: $gray29d;
      outline: none;
      background-color: transparent;
    }
  }

  .new-comment-icon {
    position: relative;
  }
  .add-comment-icon-img {
    border: 4px solid $light;
    position: absolute;
    top: 15px;
    left: -12px;
    z-index: 999;
  }

  .new-comment-name {
    font-size: $font-size-14px;
    font-family: $line-height-16px;
    color: $gray44f;
    font-weight: $font-weight-bold;
  }

  .new-comment-desig {
    font-weight: $font-weight-regular;
  }

  .new-cmment-desc {
    font-size: $font-size-14px;
    line-height: $line-height-24px;
    font-weight: $font-weight-semibold;
  }

  .edit-desc-btn {
    background-color: transparent;
    color: $gray29d;
    border: 1px solid #d5d5dc;
    outline: none;
  }
  .edit-desc-btn:focus {
    background-color: transparent;
    color: $gray29d;
    outline: none;
    border: 1px solid #d5d5dc;
  }

  .edit-desc-btn:hover {
    background-color: cadetblue;
    color: $light;
  }
}
