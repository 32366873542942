@import "../../CommonStyle/Variables.scss";
.deals-card-wrapper {
  background-color: $light;
  border-radius: 15px;
  // gap: 61px;
  padding: 30px;

  .card-wrapper {
    gap: 25px;

    .progress-bar-section {
      position: relative;

      .inner-icon {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
      }
    }

    .card-title-section {
      .card-percent {
        font-size: $font-size-24px;
        line-height: $line-height-36px;
        color: $dark75;
        font-weight: $font-weight-bold;
      }

      .card-desc {
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        color: $gray974;
        font-weight: $font-weight-regular;
      }
    }
  }
}
