body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #36454f !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.br-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.br-bottom {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.nav-link {
  color: #ffffff !important;
  font-size: 18px !important;
}

.font-1rem {
  font-size: 1.25rem;
}

@media only screen and (min-width: 1024px) {
  .custom-position-sticky {
    position: sticky;
    top: 10%;
  }
}
