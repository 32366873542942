@import "../../CommonStyle/Variables.scss";
.like-card-wrapper {
  background-color: $light;
  border-radius: 15px;
  padding: 15px 0px;

  .card-header {
    padding-bottom: 15px;
    border-bottom: 1px solid $darkf15;

    .title-class {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
      color: $dark75;
      padding-left: 20px;
    }

    .title-badge {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
      color: $blue1FF;
      padding-right: 20px;
    }
  }

  .pages-container {
    .page-item {
      padding: 20px;
      .page-title {
        font-size: $font-size-14px;
        line-height: $line-height-16px;
        font-weight: $font-weight-bold;
        color: $dark75;
      }

      .page-desc {
        font-size: $font-size-12px;
        line-height: $line-height-16px;
        font-weight: $font-weight-regular;
        color: $gray29d;
      }

      .pic-card {
        border: 1px solid $darkf15;
        border-radius: 15px;
        .card-img-otr {
          height: 180px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;

          .card-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }

        .like-area {
          padding: 14px 0px;

          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          .like-text {
            font-size: $font-size-14px;
            line-height: $line-height-24px;
            color: $gray44f;
            font-weight: $font-weight-bold;
          }

          .cl-blue {
            color: #2d17d9 !important;
          }
        }
      }
    }
  }
}
