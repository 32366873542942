@import "../../CommonStyle//Variables.scss";
.notification-card-wrappper {
  background-color: $light;
  border-radius: 15px;
  padding: 20px 0px;

  .notification-card-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $darkf15;
    padding-left: 20px;
    padding-right: 20px;

    .custom-dropdown {
      background-color: $light !important;
      padding-bottom: 1px solid $darkf15;
      color: $dark4f !important;
      border: 1px solid $lightea;
      font-size: $font-size-14px;
      font-weight: $font-weight-regular;
    }
  }

  .notification-contaner {
    padding: 10px;

    .item-wraper {
      .notiification-item {
        padding: 15px;
      }

      .custom-border-element {
        border-top: 1px solid $darkf15;
        margin-top: 5px;
      }
    }
  }

  .font-14 {
    font-size: $font-size-14px;
    font-weight: $font-weight-regular;
    line-height: $line-height-16px;
  }

  .font-12 {
    font-size: $font-size-12px;
    line-height: $line-height-22px;
    font-weight: $font-weight-regular;
  }

  .font-sb {
    font-weight: $font-weight-semibold !important;
  }

  .cl-blue {
    color: $blue2ff;
  }
  .cl-dgray {
    color: $dark75;
  }

  .cl-gray {
    color: $gray974;
  }

  .cl-gray9d {
    color: $gray29d;
  }
  .col-white {
    color: $light;
  }

  .bg-dblue {
    background-color: $blue5ff !important;
    border-radius: 15px;
  }

  .bg-light {
    background-color: #0062ff8e;
    border-radius: 15px;
  }
}
