@import "../../CommonStyle/Variables.scss";
.cover-card-wrapper {
  .cover-photo-area {
    height: 100px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .cover-photo-inr {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .profile-desc-area {
    background-color: $light;
    padding: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .profile-pic {
      position: relative;
      .Profile-pic-inr {
        position: absolute;
        top: -40px;
      }
    }
    .profile-title {
      font-size: $font-size-18px;
      line-height: $line-height-27px;
      font-weight: $font-weight-bold;
    }

    .mail-tag {
      font-size: $font-size-14px;
      font-weight: $font-weight-regular;
      line-height: $line-height-22px;
      color: $gray29d;
    }

    .profile-desc {
      font-size: $font-size-16px;
      line-height: $line-height-26px;
      color: $gray44f;
    }
  }
}

@media only screen and (max-width: 576px) {
  .Profile-pic-inr {
    top: -88px !important;
  }
}
