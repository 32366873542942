@import "../../CommonStyle/Variables.scss";
.visitorSlider-Wrapper {
  background-color: $light;
  border-radius: 15px;
  padding: 24px 25px 30px 25px;

  .badge-title {
    font-size: $font-size-14px;
    line-height: $line-height-24px;
    font-weight: $font-weight-regular;
    color: $gray44f;
  }

  .total-sales {
    .sale-title {
      font-size: $font-size-28px;
      line-height: $line-height-42px;
      color: $dark75;
      font-weight: $font-weight-bold;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-button-next {
      top: 12px;
    }

    .swiper-button-prev {
      top: 12px;
    }
    .swiper-button-next:after {
      color: $gray29d;
      font-size: $font-size-14px;
      font-weight: $font-weight-bold;
    }
    .swiper-button-prev:after {
      color: $gray29d;
      font-size: $font-size-14px;
      font-weight: $font-weight-bold;
    }

    // .swiper-button-next:after:hover {
    //   color: $blue1FF !important;
    // }
  }
}
