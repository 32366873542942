@import "../../CommonStyle/Variables.scss";
.Run-Card-Wrapper {
  padding: 15px 5px 30px 5px;
  background-color: $lightfb;
  border: 1px solid $lightea;
  border-radius: 15px;
  position: relative;

  .run-card-header {
    padding: 0px 15px;
    .run-card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      color: $gray974;
      font-weight: $font-weight-bold;
    }
  }

  .run-project-inr-card {
    .project-img {
      height: 170.45px;
      border-radius: 15px;

      .project-img-inr {
        height: 100%;
        width: 100%;
        object-fit: fill;
        border-radius: 15px;
      }
    }
  }

  .toggle-icon-btn-otr {
    position: absolute;
    bottom: -18px;
    width: 100%;
    left: 0;

    .toggle-icon-btn {
      width: 100%;
      border-radius: 30px;
      border: 1px solid $lightea;
      font-size: $font-size-30px;
      color: $gray29d;
      font-weight: $font-weight-bold;
      background-color: $light;
      outline: none;
    }

    .toggle-icon-btn:hover {
      background-color: $blue2ff;
      color: $light;
      outline: none;
    }
  }
}
