@import "../../CommonStyle/Variables.scss";
.recomended-wrapper {
  .recomended-tab-wrapper {
    padding: 21px 22px;
    background-color: $light;
    border-radius: 15px;

    .rec-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      color: $dark75;
      font-weight: $font-weight-bold;
    }

    .font-14 {
      font-size: $font-size-14px;
      line-height: $line-height-20px;
      color: $gray44f;
      font-weight: $font-weight-semibold;
    }

    .font-16 {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      color: $gray974;
      font-weight: $font-weight-bold;
    }
    .ratio-area {
      .ratio-item {
        border-left: 1px solid $darkf15;
        // border-right: 1px solid $darkf15;
        // padding: 0px 30px;
        .loss-percent {
          font-size: $font-size-16px;
          line-height: $line-height-24px;
          color: $red5fa;
          font-weight: $font-weight-bold;
        }

        .profit-percent {
          font-size: $font-size-16px;
          line-height: $line-height-24px;
          color: $green98;
          font-weight: $font-weight-bold;
        }
      }
    }

    .holding-area {
      .rec-badge {
        background-color: transparent !important;
        border: 1px solid $orange74a;
        color: $orange74a;
        font-size: $font-size-14px;
        line-height: $line-height-22px;
        font-weight: $font-weight-semibold;
      }
    }
  }
}
