@import "../../CommonStyle/Variables.scss";
.accordian-container {
  .accordion {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .accordion-item {
      border: 1px solid #e5e5e5 !important;
      border-radius: 15px;
      //   padding: 32px;
      background-color: $light;

      .accordion-header {
        border: none !important;
        background-color: $lightfb;
        padding: 10px 9px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom: 1px solid $lightea;

        .custom-search-bar {
          outline: none;
          border: none;
          width: 100%;
          background-color: transparent;
          font-size: $font-size-14px;
          line-height: $line-height-16px;
          color: $gray44f;
          font-weight: $font-weight-bold;
        }

        .accordion-button {
          background-color: transparent !important;
          .accordian-title {
            color: $dark75;

            font-size: $font-size-18px;
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: $line-height-28px;
          }
        }

        .accordion-button::after {
          //   background-image: url(../../../images/directiondown.svg);
        }

        .accordion-button:not(.collapsed) {
          background-color: transparent !important;
          box-shadow: none !important;
        }

        .accordion-button:not(.collapsed)::after {
          //   background-image: url(../../../images/directiondown.svg);
          transform: none;
        }
        .active-link:not(.collapsed)::after {
          transform: var(--bs-accordion-btn-icon-transform) !important;
        }

        .accordion-button:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }

      .accordion-collapse {
        .accordion-body {
          //   padding: 20px 23px !important;
          .profle-title {
            font-size: $font-size-14px;
            line-height: $line-height-16px;
            color: $gray44f;
            font-weight: $font-weight-bold;
          }

          .profile-mail {
            font-size: $font-size-12px;
            line-height: $line-height-16px;
            color: $gray29d;
            font-weight: $font-weight-regular;
          }
          .accordian-desc {
            letter-spacing: -0.36px;
            padding: 12px 0px 12px 0px !important;
          }
        }
      }
    }
  }
}
