@import "../../CommonStyle/Variables.scss";
.Location-wrapper {
  background-color: $light;
  border-radius: 15px;
  padding: 25px;

  .location-title {
    font-size: $font-size-16px;
    line-height: $line-height-24px;
    font-weight: $font-weight-bold;
    color: $gray725;
  }

  .sale-count {
    font-size: $font-size-28px;
    line-height: $line-height-42px;
    color: $gray725;
    font-weight: $font-weight-bold;
  }

  .count-desc {
    font-size: 14px;
    line-height: $line-height-42px;
    color: $gray74;
    font-weight: $font-weight-regular;
  }

  .fount-14 {
    font-size: $font-size-14px;
    line-height: $line-height-24px;
    color: $gray44f;
  }
  .fount-bold {
    font-weight: $font-weight-bold;
  }
  .fount-regular {
    font-weight: $font-weight-regular;
  }

  .map-img-otr {
    width: 100%;
    height: 100%;
    position: relative;
    .map-img-inr {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    .map-badge {
      position: absolute;
      top: 28%;
      left: 10%;
    }
  }
}
