@import "../../CommonStyle/Variables.scss";

.custom-wrapper {
  background-color: $light;
  padding: 25px 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card-header {
    .add-icon:hover svg path {
      fill: $green98;
    }
    .card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
    }
  }
  .Reffer-Card-wrapper {
    background-color: $light;

    padding-bottom: 20px;

    overflow-x: auto;

    .card-content-otr {
      width: 900px;
      overflow-x: auto;

      .table-header {
        padding: 11px 9px;
        border-radius: 15px;
        background-color: $lightfb;

        .header-title {
          font-size: 11px;
          line-height: $line-height-12px;
          color: $gray44f;
          font-weight: $font-weight-medium;
        }
      }

      .table-data {
        .record-row {
          padding: 15px 0px;

          .table-data-desc {
            font-size: 14px;
            line-height: $line-height-24px;
            color: $gray44f;
            font-weight: $font-weight-medium;
          }
        }
      }
    }

    .card-footer {
      padding-top: 20px;
      color: $blue6ff;
      font-size: $font-size-12px;
      line-height: $line-height-18px;
      font-weight: $font-weight-bold;
    }

    .web-section {
      width: 50%;
    }
    .view-section {
      width: 10%;
    }

    .sale-section {
      width: 10%;
    }
    .convertion-section {
      width: 20%;
    }

    .total-section {
      width: 10%;
    }
  }

  .card-footer {
    padding-top: 20px;
    color: $blue6ff;
    font-size: $font-size-12px;
    line-height: $line-height-18px;
    font-weight: $font-weight-bold;
  }

  .Reffer-Card-wrapper::-webkit-scrollbar {
    height: 5px;
    padding-top: 10px;
  }

  .Reffer-Card-wrapper::-webkit-scrollbar-thumb {
    background-color: #ff974a;
    border-radius: 15px;
  }
}
