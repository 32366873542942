.Home-Layout-wrapper {
  min-height: 100vh;
  background: transparent !important;

  .layout-menu-item {
    width: calc(100% - 30px);
  }

  .ant-layout {
    background: transparent !important;
  }

  .height-screen {
    height: 100vh;
  }

  .root-page-wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: #0a2640;
    border-radius: 15px;
    position: relative;

    .tagline-title {
      font-size: 30px;
      line-height: 40px;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      color: white;
      max-width: 460px;
    }

    .tagline-desc {
      font-size: 20px;
      color: lightgray;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
      font-style: italic;
      max-width: 460px;
    }

    .tagline-btn {
      background-color: #69e6a6;
      color: darkblue;
      font-size: 20px;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 200px;
    }
  }

  .hero-graph-img {
    object-fit: contain;
    position: relative;
    width: 80%;
    height: 100%;
  }

  .custom-sider-position {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .custom-header {
    height: unset !important;
    padding: 30px;
  }

  .layout-sider {
    flex: 0 0 250px;
    max-width: 250px;
    min-width: 250px !important;
    width: 250px !important;
  }

  .togglebtn-sidebar {
    position: relative;
  }

  .tx-underline-none {
    text-decoration: unset !important;
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .txt-none {
    text-decoration: none !important;
  }
}

.layout-content-area-collapse {
  margin-left: 80px !important;
}

@media only screen and (min-width: 1024px) {
  .Layout-content-area {
    margin-left: 250px;
  }
}

@media only screen and (min-width: 576px) {
  .layout-content-area-collapses {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 576px) {
  .hide-mobile-bar {
    display: none !important;

    transition-duration: 3s;
  }

  .show-mobile-bar {
    display: block !important;
  }

  .custom-header {
    padding: 20px !important;
  }

  .ant-layout-sider-children {
    height: 100% !important;
  }

  .layout-sider-cs {
    flex: 0 0 60px;
    max-width: 60px;
    min-width: 60px !important;
    width: 60px !important;
  }

  .layout-content-area-collapse {
    margin-left: 60px !important;
  }

  .height-screen {
    height: 100vh !important;
  }

  .search-input-field {
    /* display: none; */
    opacity: 0;
    width: 0;
    visibility: hidden;
  }

  .search-input-field-active {
    opacity: 1;
    width: 100%;
    visibility: visible;

    transition: opacity 1s, width 1s, visibility 0s linear 0s;
  }

  .search-container {
    background-color: transparent !important;
  }

  .search-bar-active {
    width: 100%;

    background-color: #36454f !important;
  }

  .title-none {
    display: none;
  }
  .title-none-active {
    display: block;
  }
}

.custom-show-bar {
  display: block !important;
}

/* .toggle-side-bar-btn {
  background: unset !important;
} */

.mobile-view-btn {
  /* position: absolute;
  top: 0px;
  left: 7px; */
  /* position: relative; */
  height: 100%;
  padding: 0px;
  line-height: 0px;
}

.about-us-tab {
  color: #ffffff;
  font-weight: 500;
  text-decoration: unset !important;
  font-size: 20px;
  text-align: center;
}

.pt-10 {
  padding-top: 10px !important;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #36454f;
  border-radius: 40px;
  padding: 10px 15px 10px 15px;
  gap: 10px;
}

.search-input-field {
  height: 20px;
  border-radius: 40px;
  outline: none;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  background-color: transparent;
  color: #ffffff;
  /* padding-left: 50px;
  padding-right: 50px; */
}

.search-input-field::placeholder {
  color: gainsboro;
}

.ant-menu-item-selected-custom {
  background: none !important;
}

/* .close-mobile-btn {
  position: absolute;
  top: 10px;
  right: 24px;
  height: 0px;
  z-index: 1;
} */

.ant-menu-item-selected-custom {
  background-color: none !important ;
}

.ant-tooltip-open{
  
}
