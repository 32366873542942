@import "../../CommonStyle/Variables.scss";
.project-schedule-wrapper {
  background-color: $light;
  border-radius: 15px;
  padding-top: 50px;

  .items-area {
    padding-left: 40px;
  }

  .schedule-item {
    padding-bottom: 21px;
    border-bottom: 1px solid $lightea;
    width: 100%;

    .date-area {
      .date-tag {
        font-size: $font-size-14px;
        line-height: $line-height-18px;
        color: $gray44f;
        font-weight: $font-weight-bold;
      }
    }

    .sch-desc-area {
      .custom-check {
        width: 20px;
        height: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #d0d5dd;
        border-radius: 6px;
        position: relative;
      }

      .custom-check:checked::before {
        content: "\2713"; /* Unicode character for checkmark */
        font-size: 16px; /* Adjust the font size of the checkmark */
        color: $light; /* Set the color of the checkmark */
        position: absolute;
        background-color: $green98;
        border-radius: 6px;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        transform: translate(-50%, -50%);
      }

      .project-title {
        font-size: $font-size-14px;
        line-height: $line-height-18px;
        color: $gray44f;
        font-weight: $font-weight-semibold;
      }
      .text-line-active {
        text-decoration: line-through;
        text-decoration-thickness: 2px;
      }

      .completed-date {
        font-size: $font-size-14px;
        line-height: $line-height-24px;
        color: $gray29d;
        font-weight: $font-weight-regular;
      }
    }
  }

  .project-card-footer {
    font-size: $font-size-14px;
    line-height: $line-height-22px;
    color: $blue2ff;
    font-weight: $font-weight-regular;
    text-decoration: underline;
    padding-top: 46px;
    padding-bottom: 47px;
  }
  .project-card-footer:hover {
    background-color: $blue2ff;
    color: $light !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
