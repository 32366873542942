@import "../../CommonStyle/Variables.scss";
.card-wrapper {
  background-color: $light;
  padding: 20px 40px 25px 20px;
  border-radius: 15px;
  gap: 20px;

  .card-header {
    .title-class {
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      letter-spacing: 0.09px;
      color: $gray29d;
      font-weight: $font-weight-regular;
    }

    .time-class {
      font-size: $font-size-12px;
      line-height: $line-height-16px;
      color: $gray44f;
      font-weight: $font-weight-regular;
    }
  }

  .card-bottom-data {
    gap: 20px;

    .profile-icon {
      padding-top: 10pxs;
    }
    .card-title-section {
      gap: 10px;
      .card-title {
        max-width: 365px;
        font-size: $font-size-16px;
        line-height: $line-height-26px;
        color: $gray44f;
        font-weight: $font-weight-medium;
        text-align: start;

        .highlight-text {
          color: $blue0FF;
        }
      }

      .check-box-area {
        gap: 10px;

        .custom-check {
          width: 20px;
          height: 20px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 1px solid #d0d5dd;
          border-radius: 6px;
          position: relative;
        }

        .custom-check:checked::before {
          content: "\2713"; /* Unicode character for checkmark */
          font-size: 16px; /* Adjust the font size of the checkmark */
          color: $light; /* Set the color of the checkmark */
          position: absolute;
          background-color: $green98;
          border-radius: 6px;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          transform: translate(-50%, -50%);
        }

        .text-title {
          font-size: $font-size-14px;
          line-height: $line-height-16px;
          color: $gray44f;
          font-weight: $font-weight-medium;
        }

        .text-line-active {
          text-decoration: line-through;
          text-decoration-thickness: 2px;
        }
      }
    }

    .bottom-data-content {
      gap: 20px;
    }
  }
}
