@import "../../CommonStyle/Variables.scss";
.average-sale-card-wrapper {
  background-color: $light;
  padding: 25px;
  border-radius: 15px;
  .card-header {
    .card-title {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
    }

    .record-year {
      font-size: $font-size-14px;
      line-height: $line-height-16px;
      font-weight: $font-weight-regular;
      color: $gray974;
    }
  }

  .progress-area {
    padding-top: 50px;
    padding-bottom: 50px;

    // .progress-circle-otr {
    //   width: 70px;
    //   height: 70px;
    // }

    border-bottom: 1px solid $darkf15;

    .cards-lists {
      gap: 46px;
    }

    .ant-progress-text {
      font-size: $font-size-24px;
      line-height: $line-height-36px;
      color: $dark75;
      font-weight: $font-weight-extra-bold;
    }

    .progress-card {
      .card-title-section {
        .card-percent {
          font-size: $font-size-24px;
          line-height: $line-height-36px;
          color: $dark75;
          font-weight: $font-weight-bold;
        }

        .card-desc {
          font-size: $font-size-14px;
          line-height: $line-height-16px;
          color: $gray974;
          font-weight: $font-weight-regular;
        }
      }
    }
  }

  .card-desc {
    font-size: $font-size-12px;
    line-height: $line-height-22px;
    color: $gray29d;
    font-weight: $font-weight-regular;
  }
}
