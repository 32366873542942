@import "../../CommonStyle/Variables.scss";

.photo-video-wrapper {
  background-color: $light;
  border-radius: 15px;
  padding: 15px 0px;

  .card-header {
    padding-bottom: 15px;
    border-bottom: 1px solid $darkf15;

    .title-class {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
      color: $dark75;
      padding-left: 20px;
    }

    .title-badge {
      font-size: $font-size-16px;
      line-height: $line-height-24px;
      font-weight: $font-weight-bold;
      color: $blue1FF;
      padding-right: 20px;
      
    }
  }

  .photo-gallery {
    padding-left: 25px;
    padding-right: 25px;
  }
}
