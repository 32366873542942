@import "../../CommonStyle/Variables.scss";
.order-wrapper {
  background-color: $light;
  padding: 30px 40px 30px 40px;
  border-radius: 15px;
  .order-count {
    font-size: $font-size-24px;
    line-height: $line-height-36px;
    color: $gray725;
    font-weight: $font-weight-bold;
    padding-bottom: 5px;
  }

  .order-title {
    font-size: $font-size-14px;
    line-height: $line-height-16px;
    color: $gray974;
    font-weight: $font-weight-regular;
    padding-bottom: 21px;
  }

  .order-percent {
    font-size: $font-size-16px;
    line-height: $line-height-24px;
    color: $green98;
    font-weight: $font-weight-medium;
  }
}
